import tw, { styled } from "twin.macro"

import { StyledButton, UnderlinedButton } from "../../../Styled/Button"

export const Wrapper = tw.div`
  flex flex-col
`

export const FreeShippingCallToAction = tw.p`
  text-center mb-1-6 text-red text-12 leading-2.08 uppercase tracking-10
`

export const ShippingMessage = tw.p`
  text-grey text-13 leading-1.53
`

export const CheckoutButton = styled(StyledButton)`
  ${tw`mb-1-6`}
`

export const ContinueShopping = styled(UnderlinedButton)`
  ${tw`mb-2 flex justify-center`}
`
