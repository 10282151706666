import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useApp } from "../../../hooks/useApp"
import { useShopify } from "../../../hooks/useShopify"

export const withRelated = Component => ({ name = "Related" }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { getCollection, getHandle, getProducts, imageNormaliser } = useShopify()

  // TODO optimize maxDepth
  const { cart } = useStaticQuery(graphql`
    query SANITY_PAGE_CART_RELATED {
      cart: sanityPageCart {
        relatedTitle
        relatedProducts: _rawRelatedProducts(resolveReferences: { maxDepth: 10 })
        relatedCollection: _rawRelatedCollection(resolveReferences: { maxDepth: 10 })
      }
    }
  `)
  const { relatedCollection, relatedProducts, relatedTitle } = cart || {}
  const [items, setItems] = useState([])

  const fetchItems = async () => {
    const items = getHandle(relatedCollection)
      ? await getCollection({ firstProducts: 10, firstImages: 2, firstVariants: 25, handle: getHandle(relatedCollection) })
      : relatedProducts?.length > 0
      ? await getProducts({ handles: relatedProducts?.map(item => getHandle(item)), firstImages: 1, firstVariants: 1 })
      : []
    setItems(formatProducts(items?.products || items))
  }

  const formatProducts = products =>
    products.map(product => ({
      images: product?.images?.map(image => imageNormaliser(image, 500)),
      key: product?.id,
      id: product?.id,
      handle: product?.handle,
      url: `${routes?.PRODUCT}/${getHandle(product)}`,
      title: product?.title,
      tags: product?.tags,
      variants: product?.variants,
      options: product?.options,
    }))

  //intentionally only run once at first render
  useEffect(() => {
    fetchItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  Component.displayName = name
  return <Component title={relatedTitle} items={items} />
}
