import React from "react"
import { Link } from "gatsby"

import { withCard } from "./withCard"
import { Wrapper, ImageWrapper, Details, Title, StyledRow } from "./CardStyles"
import { ImageWithHover } from "../../../ImageWithHover/ImageWithHover"
import { Icon } from "../../../Icon/Icon"
import { Price } from "../../../Product/Price/Price"
import { StyledSelectWrapper, StyledSelect, StyledSelectOption, StyledInputIconWrapper } from "../../../Styled/Form"
import { UnderlinedButton } from "../../../Styled/Button"

export const Card = withCard(({ handleAddToCart, loading, images, url, title, activeVariant, options, selectedOptions, handleVariant, SIZE }) => (
  <Wrapper>
    <ImageWrapper as={Link} to={url} title={title} image={images.length > 0 ? "true" : "false"}>
      <ImageWithHover image={images?.[0]} hoverImage={images?.[1]} ratio={"1/1"} />
    </ImageWrapper>
    <Details>
      <Title as={Link} to={url} title={title}>
        {title}
      </Title>
      <div>
        <div className={"flex flex-row"}>
          {options?.map((option, index) =>
            option?.values.length > 1 || option?.name === SIZE ? (
              <div className={`w-6 mr-1-2`} key={option?.name?.toString() + index}>
                <StyledSelectWrapper spacing={"true"}>
                  <StyledSelect
                    layout={"cardVariant"}
                    value={selectedOptions?.find(({ name }) => name === option?.name)?.value}
                    onChange={({ target: { value } }) => handleVariant({ name: option.name, value })}
                  >
                    <StyledSelectOption disabled value={""} selected>
                      {option?.name}
                    </StyledSelectOption>
                    {option?.values?.map((value, index) => (
                      <StyledSelectOption key={value?.toString() + index} value={value}>
                        {value}
                      </StyledSelectOption>
                    ))}
                  </StyledSelect>
                  <StyledInputIconWrapper>
                    <Icon name={"chevron"} className={`mb-0-2 mr-0-8`} width={"12"} height={"12"} />
                  </StyledInputIconWrapper>
                </StyledSelectWrapper>
              </div>
            ) : null
          )}
        </div>
        <StyledRow>
          {activeVariant ? <Price variant={activeVariant} layout={"drawer"} /> : null}
          <UnderlinedButton onClick={handleAddToCart}>
            <span>{loading ? "+ Adding" : "+ Add"}</span>
          </UnderlinedButton>
        </StyledRow>
      </div>
    </Details>
  </Wrapper>
))
