import React from "react"

import { CartItem } from "../Item/CartItem"
import { withCartList } from "./withCartList"
import { Wrapper, Title, LineItems, Total } from "./CartListStyles"

export const CartList = withCartList(
  ({ lineItems, additionalLineItemsListTitle, additionalLineItemsTotalText, count }): JSX.Element => (
    <Wrapper>
      <Title>{additionalLineItemsListTitle}</Title>
      <LineItems>
        {lineItems?.map(item => (
          <CartItem key={item?.id} item={item} />
        ))}
      </LineItems>
      <Total>
        {additionalLineItemsTotalText}&nbsp;{count}
      </Total>
    </Wrapper>
  )
)
