import tw from "twin.macro"

export const Wrapper = tw.div`
  max-w-lg mx-auto md:px-3 pt-6-2 md:pt-12-4 pb-3-6 md:pb-8
`

export const Title = tw.h1`
  text-28 leading-1.28 pb-3-2 md:text-50 md:leading-1.2 font-bold uppercase text-center border-b border-black md:border-b-0 mx-2-4
`

export const Main = tw.div`
  md:mt-4-8 grid grid-cols-1 md:grid-cols-2 gap-x-8 mb-0-8
`

export const Mobile = tw.div`
  md:hidden
`

export const Desktop = tw.div`
  hidden md:block
`

export const Loading = tw.p`
  text-center text-20
`
