import React from "react"

import { withCheckout } from "./withCheckout"
import { Wrapper, ShippingMessage, CheckoutButton, ContinueShopping } from "./CheckoutStyles"
import { Link } from "gatsby"

export const Checkout = withCheckout(
  ({
    loading,
    handleCheckout,
    // freeShippingAmount,
    continueButton,
    // freeShippingCallToAction,
    shippingNotes,
    additionalCheckoutButton,
    additionalCheckoutButtonLoading,
  }): JSX.Element => (
    <Wrapper>
      <CheckoutButton onClick={handleCheckout} colour={"red"} wide={"true"}>
        {loading ? additionalCheckoutButtonLoading : additionalCheckoutButton}
      </CheckoutButton>
      {/* {freeShippingAmount > 0 ? <FreeShippingCallToAction>{freeShippingCallToAction}</FreeShippingCallToAction> : null} */}
      <ContinueShopping as={Link} to={continueButton.url}>
        <span>{continueButton.title}</span>
      </ContinueShopping>
      <ShippingMessage>{shippingNotes}</ShippingMessage>
    </Wrapper>
  )
)
