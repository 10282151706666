import React from "react"

import { withRelated } from "./withRelated"
import { Wrapper } from "./RelatedStyles"
import { FeaturedSlider } from "../../Slider/FeaturedSlider/FeaturedSlider"
import { Card } from "./Card/Card"

export const Related = withRelated(
  ({ title, items }): JSX.Element =>
    items.length > 0 ? (
      <Wrapper>
        <FeaturedSlider id={"cart-featured-product"} title={title} items={items} Card={Card} layout={"cartProduct"} />
      </Wrapper>
    ) : null
)
