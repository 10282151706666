import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`
  flex flex-row p-1-6 md:p-2-2 bg-white
`

export const ImageWrapper = tw.div`
  w-8 h-8 md:w-8-8 md:h-8-8 mr-1-2 md:mr-2-2
`

export const Details = tw.div`
  flex flex-col justify-between flex-1
`

export const StyledRow = styled.div`
  ${tw`flex flex-row justify-between items-center w-full`}
  button {
    ${tw`pb-0-6`}
  }
`

export const Title = tw.h3`
  text-11 leading-1.27 uppercase tracking-10 font-bold
`
