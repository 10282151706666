import tw from "twin.macro"

export const Wrapper = tw.div`
  px-2-4 md:px-0 flex flex-col items-end mb-3-2 md:mb-0
`

export const Title = tw.h2`w-full text-left hidden md:block font-bold text-14 leading-1.71 uppercase tracking-10 mb-2-1`

export const LineItems = tw.ul`mb-1-6 md:mb-3-2 w-full md:border-t border-b`

export const Total = tw.p`text-18 leading-1.56 font-medium`
