import tw from "twin.macro"

export const Wrapper = tw.div`
  px-2-4 md:px-0 flex flex-col
`

export const Title = tw.h2`
  w-full text-left hidden md:block font-bold text-14 leading-1.71 uppercase tracking-10 mb-2-1
`

export const Main = tw.div`
  md:border-t py-3-2 flex flex-col
`
